<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="top">
                <div class="img">
                    <el-carousel height="360px" v-if="info.img.length > 0">
                        <el-carousel-item v-for="(item, index) in info.img" :key="index">
                            <el-image :src="item" fit="fill" style="width: 100%; height: 100%"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                    <el-image
                        :src="require('../../assets/logo2.png')"
                        fit="fill"
                        style="width: 360px; height: 360px"
                        v-else
                    ></el-image>
                </div>
                <div class="main_con_title">
                    <div class="title">{{ info.name }}</div>
                    <div class="info">
                        <div class="info-item">
                            <span class="text1">申请号：</span>
                            <span class="text2">{{ info.code }}</span>
                        </div>
                        <div class="info-item" v-if="info.applyTime">
                            <span class="text1">申请日:</span>
                            <span class="text2">{{ info.applyTime }}</span>
                        </div>
                        <div class="info-item">
                            <span class="text1">所属领域：</span>
                            <span class="text2">{{ info.industryName }}</span>
                        </div>
                        <div class="info-item" v-if="info.caseType === 'GENERAL'">
                            <span class="text1">专利类型：</span>
                            <span class="text2">{{ getLabelName(info.patentType, patentTypeOptions) }}</span>
                        </div>
                        <div class="info-item" v-if="info.ipc">
                            <span class="text1">IPC分类号：</span>
                            <span class="text2">{{ info.ipc || '暂无' }}</span>
                        </div>
                        <div class="info-item" v-if="info.caseType === 'GENERAL'">
                            <span class="text1">法律状态：</span>
                            <span class="text2">{{ getLabelName(info.lawStatus, patentStatusOptions) }}</span>
                        </div>
                        <!-- <div class="info-item">
                            <span class="text1">专利权人：</span>
                            <span class="text2" v-if="info.owner">{{ info.owner.join(',') }}</span>
                        </div> -->
                        <!-- <div class="info-item" v-if="info.ownerType">
                        <span class="text1">专利权人类型：</span>
                        <span class="text2">{{ getLabelName(info.ownerType, ownerTypeOptions) }}</span>
                    </div> -->
                        <div class="info-item">
                            <span class="text1">申请人：</span>
                            <span class="text2">{{ showList(info.applicant, ';') }}</span>
                        </div>
                        <div class="info-item">
                            <span class="text1">交易方式：</span>
                            <span class="text2">{{ getLabelName(info.tradingMethod, tradingMethodOptions) }}</span>
                        </div>
                        <div class="info-item">
                            <span class="text1">价格：</span>
                            <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                            <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                        </div>
                        <!-- <div class="info-item">
                        <span class="text1">联系人：</span>
                        <span class="text2">{{ info.contact }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">所在地区：</span>
                        <span class="text2">{{ info.address }}</span>
                    </div> -->
                    </div>
                    <div class="btn">
                        <div class="icon-button" @click.prevent="connectKefu">
                            <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                            咨询
                        </div>
                        <div class="icon-button" @click="postMessage">
                            <i class="iconfont iconfont-nav_icon_xiaoxi"></i>
                            留言
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail">
                <div class="detail_top">
                    <div class="detail-title" :class="{ active: active === 0 }" @click="active = 0">摘要</div>
                    <!-- <div class="detail-title" :class="{ active: active === 1 }" @click="active = 1">权利要求书</div> -->
                </div>
                <div class="detail_con">
                    <div class="detail-content" v-if="active === 0">{{ info.digest }}</div>
                    <div class="detail-content" v-html="info.manual" v-else></div>
                </div>
            </div>
            <!-- <div class="content">
                 -->

            <!-- <div class="sub-title">附图</div>
                <div class="ImgBox">
                    <el-carousel>
                        <el-carousel-item v-for="(item, index) in info.img" :key="index">
                            <el-image :src="getImg(item)" fit="fill"></el-image>
                        </el-carousel-item>
                    </el-carousel> -->
            <!-- <el-image v-for="(item, index) in info.img" :key="index" :src="getImg(item)" fit="cover"></el-image> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- <div class="btn">
                <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small">
                    <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                    咨询客服
                </el-button>
                <el-button @click.prevent="postMessage" type="primary" class="icon-button" size="small">
                    <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
                </el-button>
            </div> -->
        </div>
    </bread-pages>
</template>

<script>
import BreadPages from '../../components/page/BreadPages.vue';
import comEvent from '../../mixins/comEvent';
import { ownerTypeOptions, commissionTypeOptions, caseTypeOptions } from '../../utils/variables';
export default {
    data() {
        return {
            active: 0,
            info: {},
            ownerTypeOptions,
            commissionTypeOptions,
            loading: true
        };
    },
    components: {
        BreadPages
    },
    mixins: [comEvent],
    mounted() {
        this.loading = true;
        this.$http.get('/patent/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);
            let info = caseTypeOptions.find(item => {
                return item.value === res.caseType;
            });
            this.$EventBus.$emit('changePreTitle', {
                name: info.label,
                path: '/patent',
                query: {
                    caseType: res.caseType
                }
            });
            this.$EventBus.$emit('changeTitle', info.label + '详情');
            document.title = info.label + '详情';
            console.log(document.title);
        });
    },
    created() {
        let addView = {
            id: this.$route.query.id,
            type: 'Patent'
        };
        // console.log(addView);
        this.loading = true;
        this.$http.get('/all/addView/', addView).then(res => {
            console.log(res);
        });
    }
};
</script>

<style lang="less" scoped>
// .main {
//     background-color: #fff;
//     padding: 0 30px 150px;
//     position: relative;
// }
/deep/ .el-carousel__container {
    margin: 0 auto;
    height: 500px;
    width: 360px;
}
/deep/ .el-image {
    width: 100%;
    height: 100%;
}
.img {
    width: 360px;
}
.top {
    width: 1200px;
    height: 360px;
    background: #ffffff;
    border: 1px solid #f5f7fa;
    display: flex;
    margin-bottom: 50px;
    .main_con_title {
        padding-top: 20px;
        margin-left: 50px;
    }
}
.title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 33px;
    margin-bottom: 30px;
}
.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        width: 50%;
        padding-right: 50px;
        box-sizing: border-box;
        line-height: 32px;
        margin-bottom: 8px;
        .flex();

        .text1 {
            font-size: 14px;
            color: #999999;
            display: inline-block;
            min-width: 80px;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 14px;
            color: #000;
            margin-left: 6px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: @warn;
    line-height: 22px;
    padding: 50px 0 16px;
    .flex();
    &::before {
        content: '';
        width: 5px;
        height: 16px;
        background: @warn;
        border-radius: 3px;
        margin-right: 10px;
    }
}
.detail {
    width: 1200px;
    background: #ffffff;
    min-height: 768px;
    margin-bottom: 134px;
    .detail_top {
        height: 56px;
        background: #f5f7fa;
        font-size: 14px;
        font-weight: 400;
        color: #565b66;
        display: flex;
        cursor: pointer;
    }
    .detail-title {
        width: 110px;
        height: 56px;
        line-height: 56px;
        text-align: center;
    }
    .active {
        width: 110px;
        height: 56px;
        background: #ffffff;
        font-weight: bold;
        color: #01a041;
        box-sizing: border-box;
        position: relative;
        &::after {
            content: '';
            width: 110px;
            height: 3px;
            background-color: #01a041;
            position: absolute;
            left: 0px;
            top: 0px;
        }
    }
    .detail-content {
        padding: 20px 30px;
    }
    .ImgBox {
        padding-top: 60px;
    }
}
.text {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    &.imgs {
        .flex-col();
        align-items: center;
    }
}

.btn {
    margin-top: 37px;
    // text-align: center;
    .icon-button {
        width: 134px;
        height: 36px;
        border-radius: 2px;
        border: 1px solid #01a041;
        line-height: 36px;
        color: #01a041;
        justify-content: center;
        padding: 0px;
        cursor: pointer;
    }
    .icon-button:nth-of-type(1) {
        margin-right: 20px;
    }
}
.appContainer {
    background: #ffffff !important;
}
</style>
